.amp-preview-canvas {
  width: 100%;
  height: 458px;
  display: flex;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.amp-preview-canvas-progress {
  position: absolute;
  width: 100%;
  height: 458px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.amp-preview-canvas__image {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  transition: opacity 0.33s;
  position: absolute;
}

.amp-preview-canvas__image--hide {
  opacity: 0;
}

.amp-preview-canvas__interactive {
  position: absolute;
}

.amp-preview-canvas__focalpoint {
  position: absolute;
  border: 1px dashed rgba(255, 255, 255, 0.75);
  background-color: rgba(0, 41, 131, 0.33);
  transition: opacity 0.33s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amp-preview-canvas__focalcircle {
  border: 1px solid white;
  background-color: black;

  opacity: 0.33;
  width: 40%;
  height: 40%;

  border-radius: 50%;
}

.amp-preview-canvas__focalpoint--inactive {
  opacity: 0.33;
}

.amp-preview-canvas__hotspot {
  position: absolute;
  border: 1px solid white;
  background-color: rgba(0, 41, 131, 0.33);
  transition: background-color 0.33s, opacity 0.33s;

  width: 40px;
  height: 40px;

  margin: -20px;

  border-radius: 50%;
}

.amp-preview-canvas__hotspotplus {
  width: 40px;
  height: 40px;
  fill: white;
}

.amp-preview-canvas__hotspot--inactive {
  opacity: 0.33;
}

.amp-preview-canvas__hotspot--selected {
  background-color: rgba(0, 153, 255, 0.33);
  z-index: 1;
}

.amp-preview-canvas__polygon {
  position: absolute;
  stroke: white;
  stroke-dasharray: 2;
  fill: rgba(0, 41, 131, 0.33);
  transition: fill 0.33s, opacity 0.33s;
}

.amp-preview-canvas__polygon--inactive {
  opacity: 0.33;
}

.amp-preview-canvas__polygon--selected {
  fill: rgba(0, 153, 255, 0.33);
  outline: 1px solid #039be5;
  z-index: 1;
}
