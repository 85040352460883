:root {
  --color-lighter-grey: #e5e5e5;
}

.amp-edit-toolbar {
  transition: margin-top 0.5s;
  margin-top: 0;
  display: flex;
  position: relative;
  z-index: 1400;
  background-color: var(--color-lighter-grey);
  width: 100%;
  height: 42px;
}

.amp-edit-toolbar .MuiAlert-standard {
  align-items: center;
}

.amp-edit-toolbar button {
  margin: 5px;
  height: 32px;
  line-height: 32px;
  flex-shrink: 0;
}

.amp-root__topbar--hide {
  margin-top: -42px !important;
}

.amp-edit-toolbar__modescroll {
  overflow: auto;
  overflow-y: hidden;
  flex: 1;
}
  
.amp-edit-toolbar__modes {
  flex: 1;
  display: inline-flex;
}

.amp-edit-toolbar__reset {
  min-width: 0 !important;
  padding: 6px !important;
}

.amp-edit-toolbar__modeicon {
  margin-left: -3px;
  margin-right: 3px;
}
.amp-edit-toolbar__ai {
  position: relative;
}

.amp-edit-toolbar__ai__blue svg {
  fill: #039be5;
}

.amp-edit-toolbar__status {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.amp-edit-toolbar__rot45 {
  transform: rotate(45deg);
}