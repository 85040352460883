.amp-vis-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.amp-vis-page__image {
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
  transition: opacity 0.33s;
  position: absolute;
}

.amp-vis-page__image--hide {
  opacity: 0;
}

.amp-vis-page__interactive {
  position: absolute;
}

.amp-vis-page__focalpoint {
  position: absolute;
  border: 1px dashed rgba(255, 255, 255, 0.75);;
  background-color: rgba(0, 0, 0, 0.25);
  transition: opacity 0.33s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.amp-vis-page__focalcircle {
  border: 1px solid white;
  background-color: black;

  opacity: 0.33;
  width: 40%;
  height: 40%;

  border-radius: 50%;
}

.amp-vis-page__focalpoint--hidden {
  opacity: 0;
}

.amp-vis-page__hotspot {
  position: absolute;
  border: 1px solid white;
  background-color: rgba(0, 41, 131, 0.33);
  transition: background-color 0.33s, opacity 0.33s;

  width: 40px;
  height: 40px;

  margin: -20px;

  border-radius: 50%;
}

.amp-vis-page__hotspotplus {
  width: 40px;
  height: 40px;
  fill: white;
}

.amp-vis-page__hotspot--hidden {
  opacity: 0;
}

.amp-vis-page__polygon {
  position: absolute;
  stroke: white;
  stroke-dasharray: 2;
  fill: rgba(0, 41, 131, 0.33);
  transition: fill 0.33s, opacity 0.33s;
}

.amp-vis-page__polygon--hidden {
  opacity: 0;
}

.amp-vis-page__polygon--selected {
  fill: rgba(0, 153, 255, 0.33);
  outline: 1px solid #039be5;
  z-index: 1;
}